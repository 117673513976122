import React from "react";
import { Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface IProps {
  onClick: any;
  disabled?: boolean;
}

const StyledCloseButton = ({ onClick, disabled }: IProps) => {
  return (
    <Box
      sx={{
        position: "absolute", // Keeps the button in the modal corner
        top: "1px",
        right: "5px",
        zIndex: 10, // Ensures it stays on top of the modal content
      }}
    >
      <button
        style={{
          borderRadius: "50%",
          height: "65px", // Default size for the button
          width: "65px",
          backgroundColor: disabled ? "grey" : "transparent", // Dynamic background
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: disabled ? "not-allowed" : "pointer",
          transition: "background-color 0.3s ease", // Smooth hover effect
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <CancelIcon
          sx={{
            color: "#3166ba", // Icon color
            height: "55%", // Responsive scaling for the icon
            width: "55%",
          }}
        />
      </button>
    </Box>
  );
};

export default StyledCloseButton;
